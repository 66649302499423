<template lang="pug">
  div.card(:class="rules.logical=='and'? 'and-card':'or-card'")
    div.card-header
      div.row
        div.col-auto
          select.form-select(v-model="rules.logical")
            option(value="and") ALL (AND)
            option(value="or") ANY (OR)
        label.col-auto.col-form-label.ps-0 グループ
        div.col.col-form-label.text-end(v-if="!master")
          button.btn-close(type="button", @click="delete_self")

    div.card-body
      div.row
        div.col-auto.mt-1
          div.btn-group
            button.btn.btn-outline-secondary.btn-sm.dropdown-toggle(type="button", data-bs-toggle="dropdown", aria-expanded="false") 条件を追加: 対象エリアを選択
            ul.dropdown-menu
              li
                button.dropdown-item(v-for="t in targets", :key="t.id", type="button", @click="add_rule(t.id)") {{ t.label? t.label:t.id }}
        div.col-12.col-md-auto.col-form-label.col-form-label-sm.mt-1 または
        div.col-auto.mt-1
          button.btn.btn-sm.btn-outline-secondary(type="button", @click="add_group") グループを追加
      hr.mb-0
      div.row
        div.col-12
          template(v-if="rules.children.length", v-for="rule in rules.children")
            div.card.mt-3(v-if="rule.type=='rule'")
              div.card-body.p-2
                div.row.justify-content-between
                  div.col-3
                    button.btn.btn-sm.btn-link(type="button", @click="show_preview(targets.filter(e=>e.id==rule.target_id)[0])") {{ targets.filter(e=>e.id==rule.target_id)[0].label? targets.filter(e=>e.id==rule.target_id)[0].label:rule.target_id }}
                  div.col.col-form-label.col-form-label-sm.text-end
                    button.btn-close(type="button", @click="delete_rule(rule)")
                div.row.justify-content-between
                  div.col-12.col-lg-auto.mt-2
                    div.input-group
                      div.input-group-text 対象
                      select.form-select(v-model.number="rule.operand")
                        option(value="-1", disabled) 選択
                        option(value="0") 取得全文
                        option(value="1") 差分（追加）
                        option(value="2") 差分（削除）
                  div.col-12.col-lg-auto.mt-2
                    div.input-group
                      div.input-group-text 対象
                      select.form-select(v-model.number="rule.operator")
                        option(value="0", disabled) 選択
                        option(value="1") キーワードを含む
                        option(value="2") キーワードを含まない
                        option(value="3") xより大きい数値を含む
                        option(value="4") xより小さい数値を含む
                        option(value="5") x以上増加した数値を含む<sup>*</sup>
                        option(value="6") x以上減少した数値を含む<sup>*</sup>
                        option(value="7") x%以上増加した数値を含む<sup>*</sup>
                        option(value="8") x%以上減少した数値を含む<sup>*</sup>
                        option(value="9") 正規表現にマッチする部分を含む
                        option(value="10") 正規表現にマッチする部分を含まない
                  div.col-12.col-lg-auto.mt-2
                    input.form-control(type="text", v-model="rule.value", :placeholder="value_placeholder[rule.operator]")
            RulesCard.mt-3(v-else, :rules="rule", :targets="targets", :master="false", @delete_group="delete_rule", @show_preview="show_preview")
          div.help-label(v-if="rules.children.length==0")
            p.mt-3.mb-0(v-if="master") 条件が設定されていません。監視エリアのいずれかで更新を検知した場合に通知されます。
            p.mt-3.mb-0(v-else) 条件が設定されていません。登録時にこのグループは自動で削除されます。
              



</template>

<script>
import RulesCard from '@/components/RulesCard.vue'

export default {
  name: 'RulesCard',
  components: {
    RulesCard
  },
  props:[
    "rules", "targets", "master"
  ],
  data (){
    return {
      value_placeholder: {
        0: "条件を選択してください",
        1: "キーワードを入力",
        2: "キーワードを入力",
        3: "x（数値）を入力",
        4: "x（数値）を入力",
        5: "x（数値）を入力",
        6: "x（数値）を入力",
        7: "x（数値）を入力",
        8: "x（数値）を入力",
        9: "正規表現(Python)を入力",
        10: "正規表現(Python)を入力"
      },
    }
  },
  mounted (){
  },
  computed: {
  },
  methods: {
    add_rule (target_id){
      this.rules.children.push({ type:"rule", target_id:target_id, operator:0, operand:1, value:"" });
    },
    delete_rule (rule){
      this.rules.children.splice(this.rules.children.indexOf(rule),1);
      // 確認ありver.
      // if (rule.type=="group" && rule.children.length===0)
      //   this.rules.children.splice(this.rules.children.indexOf(rule),1);
      // else {
      //   this.$swal(`${rule.type=="rule"? "条件":"グループ"}を削除しますか？${rule.type=="rule"? "":"\n(グループ内の条件もすべて削除されます。)"}\nこの操作は取り消せません。`, { buttons:[true,true] })
      //   .then((val)=>{
      //     if(val)
      //       this.rules.children.splice(this.rules.children.indexOf(rule),1);
      //   });
      // }
    },
    add_group (){
      this.rules.children.push({ logical:"and", type:"group", children:[] });
    },
    delete_self(){
      this.$emit("delete_group", this.rules);
    },
    show_preview (target){
      this.$emit("show_preview", target)
    }
  }
}
</script>

<style lang="scss" scoped>
.and-card {
  border-left: 3px solid lightcoral;
}

.or-card {
  border-left: 3px solid cornflowerblue;
}

.help-label {
  font-size: .85rem;
}
</style>
