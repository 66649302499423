import { render, staticRenderFns } from "./PreRulesCard.vue?vue&type=template&id=00f3515f&scoped=true&lang=pug&"
import script from "./PreRulesCard.vue?vue&type=script&lang=js&"
export * from "./PreRulesCard.vue?vue&type=script&lang=js&"
import style0 from "./PreRulesCard.vue?vue&type=style&index=0&id=00f3515f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f3515f",
  null
  
)

export default component.exports